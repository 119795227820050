import React from "react";

import { Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import parse from "html-react-parser";

import { config } from "@fortawesome/fontawesome-svg-core";
import { faChevronCircleLeft } from "@fortawesome/pro-solid-svg-icons";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

import "@fortawesome/fontawesome-svg-core/styles.css";

import Link from "@Js/Component/Link/Link";

import classNames from "@Css/Component/Breadcrumbs/Breadcrumbs.module.scss";

config.autoAddCss = false;

export type BreadcrumbsProps = Readonly<{
    breadcrumbs: {
        text: string;
        url: string;
    }[];
}>;

const Breadcrumbs = (props: BreadcrumbsProps) => {
    const {
        breadcrumbs
    } = props;

    const length = breadcrumbs.length;

    return (
        <nav className={ classNames.breadcrumbs }>
            <ul className={ classNames.list }>
                { breadcrumbs.map((breadcrumb, index) => (
                    <li className={ classNames.listItem } key={ index }>
                        { index + 1 < length &&
                            <Fragment>
                                <Link
                                    className={ classNames.link }
                                    to={ breadcrumb.url }
                                >
                                    { parse(breadcrumb.text) }
                                </Link>
                                <FontAwesomeIcon className={ classNames.icon } icon={ faChevronRight } />
                            </Fragment>
                        }
                        { index + 1 == length &&
                            <Link
                                className={ classNames.link }
                                to={ breadcrumb.url }
                            >
                                { parse(breadcrumb.text) }
                            </Link>
                        }
                    </li>
                )) }
                <li className={ classNames.listItem }>
                    <Link className={ classNames.link } to={ breadcrumbs[length - 2].url }>
                        Terug <FontAwesomeIcon icon={ faChevronCircleLeft } />
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default Breadcrumbs;
