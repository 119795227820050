import React from "react";

import { Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import clsx from "clsx";

import { config } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { graphql } from "gatsby";

import Breadcrumbs from "@Js/Component/Breadcrumbs/Breadcrumbs";
import EventGrid from "@Js/Component/EventGrid/EventGrid";
import Link from "@Js/Component/Link/Link";

import classNames from "@Css/Component/Template/Category.module.scss";
import moment from "moment";

config.autoAddCss = false;

export type EventsProps = Readonly<{
    data: {
        allWordpressWpEvent: {
            edges: {
                node: {
                    acf: {
                        event_datetime: string;
                        event_datetime_formatted: string;
                        event_location: string;
                    };
                    excerpt: string;
                    slug: string;
                    title: string;
                    wordpress_id: string;
                };
            }[];
        };
    };
    pageContext: {
        basePath: string;
        current: number;
        first: number;
        last: number;
        limit: number;
        next: number | null;
        previous: number | null;
    };
}>;

export const pageQuery = graphql`
    query($limit: Int!, $skip: Int!) {
        allWordpressWpEvent(limit: $limit, skip: $skip, sort: { fields: acf___event_datetime, order: ASC }) {
            edges {
                node {
                    acf {
                        event_datetime
                        event_datetime_formatted: event_datetime(formatString: "D MMM YYYY \\o\\m HH:mm", locale: "NL-nl")
                        event_location
                    }
                    excerpt
                    slug
                    title
                    wordpress_id
                }
            }
        }
    }
`;

const Events = (props: EventsProps) => {
    const eventEdges = props.data.allWordpressWpEvent.edges.filter((eventEdge) => {
        return moment(eventEdge.node.acf.event_datetime).isAfter();
    });
    const pageContext = props.pageContext;

    const range = 2;
    const margin = 2;

    const items: number[] = [];

    if (pageContext.last <= range) {
        for (let i = 0; i < pageContext.last; i++) {
            items.push(i + 1);
        }
    }
    else {
        let leftSide = range / 2;
        let rightSide = range - leftSide;

        if (pageContext.current > pageContext.last - range / 2) {
            rightSide = pageContext.last - pageContext.current;
            leftSide = range - rightSide;
        } else if (pageContext.current < range / 2) {
            leftSide = pageContext.current;
            rightSide = range - leftSide;
        }

        for (let i = 0; i < pageContext.last; i++) {
            if (i + 1 <= margin) {
                items.push(i + 1);

                continue;
            }

            if (i + 1 > pageContext.last - margin) {
                items.push(i + 1);

                continue;
            }

            if (i >= pageContext.current - leftSide && i <= pageContext.current + rightSide) {
                items.push(i + 1);

                continue;
            }

            if (!(items[items.length - 1] == -1)) {
                items.push(-1);
            }
        }
    }

    return (
        <Fragment>
            <Breadcrumbs breadcrumbs={ [{ text: "Home", url: "/" }, { text: "Agenda", url: "/agenda" }] } />
            <main>
                <section className={ classNames.category }>
                    <header className={ classNames.header }>
                        <h1 className={ classNames.title }>Agenda</h1>
                    </header>

                    <EventGrid eventEdges={ eventEdges } />

                    <div className={ classNames.pagination }>
                        { pageContext.previous &&
                            <Link
                                className={ clsx(classNames.button, classNames.previousButton) }
                                to={ pageContext.basePath + "/" + pageContext.previous }
                            >
                                <FontAwesomeIcon icon={ faChevronLeft } />
                            </Link>
                        }
                        { items.map((item, index) => (
                            <Fragment key={ index }>
                                { item == -1 &&
                                    <span className={ classNames.break }>...</span>
                                }
                                { !(item == -1) &&
                                    <Link
                                        className={ clsx(classNames.button, {
                                            [classNames.currentButton]: item == pageContext.current
                                        } ) }
                                        to={ pageContext.basePath + "/" + item }
                                    >
                                        { item }
                                    </Link>
                                }
                            </Fragment>
                        )) }
                        { pageContext.next &&
                            <Link
                                className={ clsx(classNames.button, classNames.nextButton) }
                                to={ pageContext.basePath + "/" + pageContext.next }
                            >
                                <FontAwesomeIcon icon={ faChevronRight } />
                            </Link>
                        }
                    </div>
                </section>
            </main>
        </Fragment>
    );
};

export default Events;
