import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { HTMLProps } from "react";

import clsx from "clsx";

import parse from "html-react-parser";

import { config } from "@fortawesome/fontawesome-svg-core";
import { faCalendar } from "@fortawesome/pro-solid-svg-icons";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { faMapMarker } from "@fortawesome/pro-solid-svg-icons";

import "@fortawesome/fontawesome-svg-core/styles.css";

import Link from "@Js/Component/Link/Link";

import classNames from "@Css/Component/EventGrid/EventGrid.module.scss";

config.autoAddCss = false;

type EventEdge = {
    node: {
        acf: {
            event_datetime: string;
            event_datetime_formatted: string;
            event_location: string;
        };
        excerpt: string;
        slug: string,
        title: string;
        wordpress_id: string;
    };
};

export type EventGridProps = Readonly<HTMLProps<HTMLElement> & {
    eventEdges: EventEdge[];
    showMore?: boolean;
}>;

const EventGrid = (props: EventGridProps) => {
    const {
        className,
        eventEdges,
        showMore,
        ...restProps
    } = props;

    return (
        <section { ...restProps } className={ clsx(classNames.eventGrid, className) }>
            <ul className={ classNames.list }>
                { eventEdges.map((edge) => (
                    <li className={ classNames.listItem } key={ edge.node.wordpress_id }>
                        <Link className={ classNames.anchor } to={ "/" + edge.node.slug }>
                            <section className={ classNames.event }>
                                <h1 className={ classNames.title }>{ edge.node.title }</h1>
                                <div className={ classNames.location }>
                                    <FontAwesomeIcon className={ classNames.icon } icon={ faMapMarker } />
                                    <span>{ edge.node.acf.event_location }</span>
                                </div>
                                <div className={ classNames.date }>
                                    <FontAwesomeIcon className={ classNames.icon } icon={ faCalendar } />
                                    <time>{ edge.node.acf.event_datetime_formatted }</time>
                                </div>
                                <div className={ classNames.excerpt }>{ parse(edge.node.excerpt) }</div>
                                <div className={ classNames.readMore }>
                                    <span>Lees meer</span>
                                    <FontAwesomeIcon className={ classNames.icon } icon={ faChevronRight } />
                                </div>
                            </section>
                        </Link>
                    </li>
                )) }

                { showMore && (
                    <li className={ classNames.listItem }>
                        <Link className={ classNames.anchor } to="/agenda">
                            <section className={ classNames.event }>
                                <div className={ classNames.readAll }>
                                    <span>Bekijk alle agendapunten</span>
                                    <FontAwesomeIcon className={ classNames.icon } icon={ faChevronRight } />
                                </div>
                            </section>
                        </Link>
                    </li>
                ) }
            </ul>
        </section>
    );
};

export default EventGrid;
